<template>
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.6002 70.5C55.654 70.5 71.1002 55.0537 71.1002 36C71.1002 16.9462 55.654 1.5 36.6002 1.5C17.5465 1.5 2.10022 16.9462 2.10022 36C2.10022 55.0537 17.5465 70.5 36.6002 70.5Z"
      fill="url(#paint0_linear_1073_4138)"
      style="" />
    <path
      d="M48.6003 37.5H36.6003C36.1503 37.5 35.7003 37.2 35.4003 36.9L23.4003 18.9C22.9503 18.15 23.1003 17.25 23.8503 16.8C24.6003 16.35 25.5003 16.5 25.9503 17.25L37.3503 34.5H48.6003C49.5003 34.5 50.1003 35.1 50.1003 36C50.1003 36.9 49.5003 37.5 48.6003 37.5Z"
      fill="#BBF7D0"
      style="fill: #bbf7d0; fill: color(display-p3 0.7333 0.9686 0.8157); fill-opacity: 1" />
    <path
      d="M35.1003 7.5C35.1003 6.6 35.7003 6 36.6003 6C37.5003 6 38.1003 6.6 38.1003 7.5V13.5C38.1003 14.4 37.5003 15 36.6003 15C35.7003 15 35.1003 14.4 35.1003 13.5V7.5Z"
      fill="#BBF7D0"
      style="fill: #bbf7d0; fill: color(display-p3 0.7333 0.9686 0.8157); fill-opacity: 1" />
    <path
      d="M14.1003 37.5H8.10034C7.20034 37.5 6.60034 36.9 6.60034 36C6.60034 35.1 7.20034 34.5 8.10034 34.5H14.1003C15.0003 34.5 15.6003 35.1 15.6003 36C15.6003 36.9 15.0003 37.5 14.1003 37.5Z"
      fill="#BBF7D0"
      style="fill: #bbf7d0; fill: color(display-p3 0.7333 0.9686 0.8157); fill-opacity: 1" />
    <path
      d="M38.1003 64.5C38.1003 65.4 37.5003 66 36.6003 66C35.7003 66 35.1003 65.4 35.1003 64.5V58.5C35.1003 57.6 35.7003 57 36.6003 57C37.5003 57 38.1003 57.6 38.1003 58.5V64.5Z"
      fill="#BBF7D0"
      style="fill: #bbf7d0; fill: color(display-p3 0.7333 0.9686 0.8157); fill-opacity: 1" />
    <path
      d="M65.1003 37.5H59.1003C58.2003 37.5 57.6003 36.9 57.6003 36C57.6003 35.1 58.2003 34.5 59.1003 34.5H65.1003C66.0003 34.5 66.6003 35.1 66.6003 36C66.6003 36.9 66.0003 37.5 65.1003 37.5Z"
      fill="#BBF7D0"
      style="fill: #bbf7d0; fill: color(display-p3 0.7333 0.9686 0.8157); fill-opacity: 1" />
    <defs>
      <linearGradient id="paint0_linear_1073_4138" x1="36.6002" y1="1.5" x2="36.6002" y2="70.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#86EFAC" style="stop-color: #86efac; stop-color: color(display-p3 0.5255 0.9373 0.6745); stop-opacity: 1" />
        <stop offset="1" stop-color="#17AE4F" style="stop-color: #17ae4f; stop-color: color(display-p3 0.0902 0.6824 0.3098); stop-opacity: 1" />
      </linearGradient>
    </defs>
  </svg>
</template>
